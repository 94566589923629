import React, { useEffect, useState } from 'react'
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from 'axios';
import { LIVEURLNEW, LIVEURLNEW2 } from '../utils/constant';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from "yup";
// import "yup-phone";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import parsePhoneNumber from 'libphonenumber-js'

yup.addMethod(yup.string, 'mobile', function (message) {
    return this.test('mobile', message, function (value) {
      const { path, createError } = this;
      if (!value) return true; // Allow empty values, use .required() for mandatory fields
      const phoneNumber = parsePhoneNumber(`+${value}`);
      return (
        (phoneNumber && phoneNumber.isValid()) ||
        createError({ path, message: message || 'Invalid phone number' })
      );
    });
  });

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const validationSchema = yup
    .object({
        mobile: yup.string().mobile('Invalid phone number').required('Phone number is required'),
        block_no: yup.string().required("This fiels is required"),
        flat_no: yup.string().required("This fiels is required"),
    })
export default function SendLinkToLandlord() {
    const navigate = useNavigate()
    const {
        register,
        setValue,
        getValues,
        formState: { errors },
        handleSubmit
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const [landlordAdded, setLandlordAdded] = useState(false);


    const apiCall = async () => {
        try{
            let formData = getValues();
            const { status } = await axios.post(`${LIVEURLNEW2}RWA/sendLinkToLandlord`, { ...formData }, { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } });
            if (status == 201 || status == 200) {
                toast.success("Link shared successfully")
                setLandlordAdded(true)
                return;
            }
            toast.error("Something went wrong")
        }catch(e){
            if(e?.response?.data?.message){
                toast.error(e?.response?.data?.message)
            }
        }
       
    }

        return (
        <>
            <header>
                <div className="container">
                    <div className="app-new-auth-header">
                        <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png" />
                        <Button
                            style={{ marginTop: 10 }}
                            onClick={() => {
                                localStorage.clear()
                                navigate("/rwaLogin")
                            }}
                            variant="contained"
                        >Logout</Button>
                    </div>
                </div>
            </header>
            <div className="app-new-auth-page login-bg-image">
                <form onSubmit={handleSubmit(apiCall)}>
                    <div className="container h-100">
                        <div className="app-new-auth-page-wrapper">
                            <div className="app-new-auth-page-heading">
                                <h1>Welcome to {JSON.parse(localStorage.getItem("user"))?.name}</h1>
                                <p>Please enter landlord details</p>
                            </div>
                            <div className='app-new-auth-page-card app-new-auth-page-card-tenant'>
                                {landlordAdded ? <div className='d-flex justify-content-center align-items-center flex-column'>
                                    <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                                    <span className='font-weight-bold' >Thanks for sending link to landlord.</span>
                                    <span className='font-weight-bold' style={{marginTop:10,marginBottom:10}}>Do you want to send link to another landlord ? </span>
                                    <Button
                                        variant="contained"
                                        onClick={() => { setLandlordAdded(false) }}
                                    >
                                        Send Link
                                    </Button>

                                </div> : <ul className="app-login-form-field-group">
                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="app-register-form-field">

                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} lg={12}>
                                                        <span>Landlord Whatsapp number</span>
                                                        <PhoneInput
                                                            country={'in'}
                                                            value={getValues().mobile}
                                                            onChange={(value) => {setValue("mobile",value)}}
                                                            countryCodeEditable={false}
                                                            enableSearch={true}
                                                            placeholder="Landlord Whatsapp number"
                                                            containerStyle={{marginTop:5}}
                                                            inputStyle={{ width: '100%' ,height:50}}
                                                        />
                                                        {errors?.mobile && <span className="app-warning-content">{errors?.mobile?.message}</span>}
                                                        </Grid>
                                                        
                                                        <Grid item xs={12} lg={12}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="Landlord Flat number"
                                                                style={{border:'1px solid #DBE5ED',width:'100%',height:50,paddingLeft:10,marginBottom:20}}
                                                                {...register("flat_no")}
                                                            />
                                                            {errors?.flat_no && <span className="app-warning-content">{errors?.flat_no?.message}</span>}

                                                        </Grid>

                                                    </Grid>
                                                </div>

                                                <div className="app-register-form-field">

                                                    <Grid container spacing={2}>

                                                        <Grid item xs={12} lg={12}>
                                                            
                                                            <input
                                                                type={"text"}
                                                                placeholder="Landlord Block number"
                                                                style={{border:'1px solid #DBE5ED',width:'100%',height:50,paddingLeft:10}}
                                                                {...register("block_no")}
                                                            />
                                                            {errors?.block_no && <span className="app-warning-content">{errors?.block_no?.message}</span>}

                                                        </Grid>

                                                    </Grid>
                                                </div>


                                            </Grid>
                                        </Grid>
                                    </li>
                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="app-auth-button justify-content-center">
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        Send Link
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </li>
                                </ul>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
