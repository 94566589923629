import React, { useEffect, useState } from 'react'
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import DownChevron from "../icons/DownChevron";
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { LIVEURLNEW, LIVEURLNEW2 } from '../utils/constant';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import * as yup from "yup";
import "yup-phone";
import { Checkbox } from '@mui/material';
import PhoneInput from "react-phone-input-2";
import parsePhoneNumber from 'libphonenumber-js'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

yup.addMethod(yup.string, 'mobile', function (message) {
    return this.test('mobile', message, function (value) {
      const { path, createError } = this;
      if (!value) return true; // Allow empty values, use .required() for mandatory fields
      const phoneNumber = parsePhoneNumber(`+${value}`);
      return (
        (phoneNumber && phoneNumber.isValid()) ||
        createError({ path, message: message || 'Invalid phone number' })
      );
    });
  });


export default function AddLandlordTenantByRWA() {

    const { id } = useParams();

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [shortLinkDetails, setShortLinkDetails] = useState(null);
    const [rwaDetails, setRwadetails] = useState(null)
    const [currentStep, setCurrentStep] = useState(1)
    const [tenantAdded, setTenantAdded] = useState(false);
    const [createdDetails, setCreatedDetails] = useState({});
    const [currentStatus,setCurrentStatus] = useState(null)
    const [verificationServices,setVerificationServices] = useState(null);
    const [selectedServices,setSelectedServices] = useState([])
    const [transactionDetails, setTransactionDetails] = useState(null)

    const getValidationSchema = () => {
        const PHONE_NO_REGEX = /^[0-9\- ]{8,14}$/
        return currentStep == 1 ? yup
            .object({
                first_name: yup.string().required("It is a required field"),
                last_name: yup.string().required("It is a required field"),
                email: yup.string().email().required("It is a required field"),
                mobile: yup.string().mobile('Invalid phone number').required('Phone number is required'),

                // mobile: yup.string().matches(PHONE_NO_REGEX,{message:"not a valid phone no",excludeEmptyString:true}).length(10),

            }) : currentStep == 2 ? yup
                .object({
                    flat_number: yup.string().required("It is a required field"),
                    block_number: yup.string().required("It is a required field"),
                    no_of_bedooms: yup.string().required("It is a required field"),
                    no_of_bathooms: yup.string().required("It is a required field"),
                    no_of_carparks: yup.string().required("It is a required field"),
                    square_feet: yup.string().required("It is a required field"),
                    property_face: yup.string().required("It is a required field"),
                }) : yup
                    .object({
                        tenant_first_name: yup.string().required("It is a required field"),
                        tenant_last_name: yup.string().required("It is a required field"),
                        tenant_email: yup.string().email().required("It is a required field"),
                        type: yup.string().required("It is a required field"),
                        tenant_mobile: yup.string().matches(PHONE_NO_REGEX,{message:"not a valid phone no",excludeEmptyString:true}).length(10),
                    })
    }

    const validationSchema = getValidationSchema();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const getVerificationServices = async() => {
        try {
            const { data, status } = await axios.get(`${LIVEURLNEW2}service/`)
            if (status === 200) {
                setVerificationServices(data)
                const idAddressId = data?.find(item => item?.name === "ID & Address Verification")?._id
                setSelectedServices([idAddressId])
            } else {
                setError(true)
            }
        } catch (e) {
            setError(true)
        }
    }


    const createLandlord = async (formValues) => {
        try {
            setLoading(true)
            const payload = { ...formValues, type:"RWA" , rwa: rwaDetails?._id, shortLinkId: id}
            const { status, data } = await axios.post(`${LIVEURLNEW2}Landlord/register`, payload);
            if (status == 200 || status == 201) {
                toast.success("Landlord added successfully")
                setCreatedDetails({ ...createdDetails, landlord: { ...data?.user, landlordToken: data?.accessToken } })
                setCurrentStep(currentStep + 1)
                setLoading(false)
            } else {
                toast.error("Something went wrong, Try again")
                setLoading(false)
            }
        } catch (e) {
            if (e?.response?.status == 400) {
                setCreatedDetails({ ...createdDetails, landlord: { ...e?.response?.data?.user, landlordToken: e?.response?.data?.landlordToken } })
                setLoading(false)
                setCurrentStep(currentStep + 1)
                return
            }
            toast.error("Something went wrong, Try again")
            setLoading(false)
        }
    }

    const createTenant = async (transId,isRedeem) => {
        const formValues = getValues();
        try {
            setLoading(true)
            const payload = {
                first_name: formValues?.tenant_first_name,
                last_name: formValues?.tenant_last_name,
                email: formValues?.tenant_email,
                mobile: `91${formValues?.tenant_mobile}`,
                shortLinkId: id,
                property: createdDetails?.property?._id,
                rwa: rwaDetails?._id,
                selectedServices,
                type: formValues.type,
                paymentHistory: transId ?? null,
                landlordStatusId : shortLinkDetails?.generatedId,
                coTenantsCount : formValues?.coTenantsCount ? Number(formValues?.coTenantsCount) : null,
            }
            const { status } = await axios.post(`${LIVEURLNEW2}tenant/`, payload, { headers: { Authorization: `Bearer ${createdDetails?.landlord?.landlordToken}` } });
            if (status == 200 || status == 201) {
                await updatePaymentTransaction("Service Availed", null, transId, "createApi")
                toast.success("Tenant added successfully")
                if (isRedeem) await redeemCoupon(formValues.type);
                setTenantAdded(true)
                setLoading(false)
            } else {
                toast.error("Something went wrong, Try again")
                setLoading(false)
            }
        } catch (e) {
            toast.error("Something went wrong, Try again")
            setLoading(false)
        }
    }

    const createProperty = async (formValues) => {
        console.log(createdDetails)
        try {
            setLoading(true)
            const payload = {
                flat_no: formValues?.flat_number,
                block_no: formValues?.block_number,
                no_of_bedooms: formValues?.no_of_bedooms,
                no_of_bathooms: formValues?.no_of_bathooms,
                no_of_carparks: formValues?.no_of_carparks,
                square_feet: formValues?.square_feet,


                building_name: rwaDetails?.name,
                description: rwaDetails?.name,
                state: rwaDetails?.state,
                city: rwaDetails?.city,
                pincode: rwaDetails?.pincode,
                property_type: "RWA",
                property_status: "Vacant",
                property_face: formValues?.property_face,
                address: rwaDetails?.address,


                landlord: createdDetails?.landlord?._id, 
                rwa: rwaDetails?._id,

                shortLinkId: id,
                landlordStatusId : shortLinkDetails?.generatedId
            }
            const { status, data } = await axios.post(`${LIVEURLNEW}addProperty`, payload );
            if (status == 200 || status == 201) {
                toast.success("Flat details added successfully")
                setCreatedDetails({ ...createdDetails, property: data.property })
                setCurrentStep(currentStep + 1)
                setLoading(false)
            } else {
                toast.error("Something went wrong, Try again")
                setLoading(false)
            }
        } catch (e) {
            toast.error("Something went wrong, Try again")
            setLoading(false)
        }
    }



    const getShortLinkDetails = async () => {
        try {
            const { data: shortLinkUrl, status: shortStatus } = await axios.get(`${LIVEURLNEW}getShortLinkDetails/${id}`)
            if (shortStatus == 200) {
                setRwadetails(shortLinkUrl?.rwaDetails)
                setValue("flat_number", shortLinkUrl?.flat_no)
                setValue("block_number", shortLinkUrl?.block_no)
                setValue("mobile", shortLinkUrl?.mobile)
                setShortLinkDetails(shortLinkUrl)
                setCurrentStatus(shortLinkUrl?.status)
                if(shortLinkUrl?.status == "Started"){
                    setCurrentStep(1)
                }else if(shortLinkUrl?.status == "LandlordRegisterd"){
                    setCurrentStep(2)
                    setCreatedDetails({...createdDetails,landlord: shortLinkUrl?.landlord})
                }else if(shortLinkUrl?.status == "PropertyRegistered"){
                    setCurrentStep(3)
                    setCreatedDetails({...createdDetails,landlord: shortLinkUrl?.landlord,property: shortLinkUrl?.property})
                }else{
                    setError(true)
                }
            } else {
                setError(true)
            }
        } catch (e) {
            setError(true)
        }
    }

    const createTransaction = async (isRedeem) => {
        try {
            setLoading(true)
            if (isRedeem && selectedServices.length === 1) {
                const payload = {
                    "status": "Success",
                    "property": createdDetails?.property?._id,
                    "paymentType": "Verification",
                    "amount": 0,
                    "orderAmount": 0,
                    "convenienceFee": 0.0,
                    "gstAmount": 0.0,
                    "amountExcludingGST":0.0,
                    "platformFee": 0.0,
                    "paymentGateway": "Razorpay",
                    "gstPercentage": 18,
                    "gstName": "IGST",
                    "transactionDetails": {}
                }
                const { status, data } = await axios.post(`${LIVEURLNEW2}paymentHistory`, payload, { headers: { Authorization: `Bearer ${createdDetails?.landlord?.landlordToken}` } });
                if (status == 200 || status == 201) {
                    createTenant(data?._id, isRedeem);
                }
                setLoading(false)
                return

            }
            const finalCostableServices = verificationServices.filter(item => selectedServices.includes(item?._id) && (item?.name.includes("ID &") === true && isRedeem ? false : true ))
            const coTenantsCount = Number(getValues()?.coTenantsCount)
            const totalPrice = finalCostableServices.reduce((acc, item) => hasCotenantService() && item.name.includes('Co-tenants') ? acc + (item.price * coTenantsCount) : acc + item.price, 0);

            const payload = {
                "status": "Initiated",
                "property": createdDetails?.property?._id,
                "paymentType": "Verification",
                "amount": totalPrice + (totalPrice * 18) / 100,
                "orderAmount": (totalPrice + ((totalPrice * 18) / 100)) * 100,
                "convenienceFee": 0.0,
                "gstAmount": (totalPrice * 18) / 100,
                "amountExcludingGST": totalPrice,
                "platformFee": 0.0,
                "gstName": "IGST",
                "gstPercentage": 18,
                "paymentGateway": "Razorpay",
                "transactionDetails": {}
            }
            const { status: paymentStatus, data } = await axios.post(`${LIVEURLNEW2}paymentHistory/createRazorpayOrderId`, payload, { headers: { Authorization: `Bearer ${createdDetails?.landlord?.landlordToken}` } });
            if (paymentStatus == 200 || paymentStatus == 201) {
                setTransactionDetails(data);
                var options = {
                    "key": "rzp_live_6VGdaqTcn0KMhz",
                    "amount": (totalPrice * 100).toString(),
                    "currency": "INR",
                    "name": "Score10",
                    "description": "Tenant Verification Request",
                    "image": "https://score10.in/images/landing-page/logo3.png",
                    "handler": async function (response) {
                        console.log(response, "responseresponse")
                        if (response?.razorpay_payment_id || response?.status == 200 ) {
                           await updatePaymentTransaction("Success", { details: response }, data?._id, "transactionApi")
                        } else {
                            await updatePaymentTransaction("Failed", { details: response }, data?._id, "transactionApi")
                            toast.error("Transaction failed")
                        }
                    },
                    "order_id": data?.orderDetails?.id,
                    "theme": {
                        "color": "#3399cc"
                    }
                };
                console.log(options, "orderDetails")
                var rzp1 = new window.Razorpay(options);

                await rzp1.open();
                setLoading(false)
            }
        } catch (e) {
            toast.error("Something went wrong while initiating the transaction")
            setLoading(false);
        }
    }

    const updatePaymentTransaction = async (status, transactionDetailsNew, id, isFor) => {
        try {
            setLoading(true)
            let payload = {
                "status": status,
                "transactionDetails": transactionDetailsNew
            }
            if (transactionDetailsNew == null) delete payload.transactionDetails;
            const { status: paymentStatus } = await axios.patch(`${LIVEURLNEW2}paymentHistory/${id}`, payload, { headers: { Authorization: `Bearer ${createdDetails?.landlord?.landlordToken}` } });
            if (paymentStatus == 200 || paymentStatus == 201) {
                if (isFor == 'createApi') {
                    setLoading(false)
                    return
                }
                createTenant(id,selectedServices.length === 1 ? false : true);
                setLoading(false)
                return true
            }
        } catch (e) {
            setLoading(false);
            return false

        }
    }

    const checkRewards = async (formValues) => {
        if(hasCotenantService() && !getValues()?.coTenantsCount) return
        try{
            setLoading(true);
            const {status} = await axios.get(`${LIVEURLNEW2}rewards/checkReward/${formValues?.type}`,{ headers: { Authorization: `Bearer ${createdDetails?.landlord?.landlordToken}` } });
            if(status === 200) {createTransaction(true);}
            else{createTransaction(false);}
        }catch(e){
            if(e?.response?.status === 404){
                createTransaction(false);
                return
            }
            toast.error(e?.message)
            setLoading(false);
        }
    }

    const redeemCoupon = async (type) => {
        try {
            const { status } = await axios.patch(`${LIVEURLNEW2}rewards/updateReward/${type}`, {}, { headers: { Authorization: `Bearer ${createdDetails?.landlord?.landlordToken}` } });
            if(status === 200) return true;
            return false
        } catch (e) {
            return false
        }

    }

    const hasCotenantService = () => {
       return verificationServices?.findIndex(item => item.name.includes("Co-tenants") && selectedServices.includes(item?._id)) >= 0;
    }

    useEffect(() => {
        getShortLinkDetails();
        getVerificationServices();
    }, [])


    return (
        <>
            <header>
                <div className="container">
                    <div className="app-new-auth-header">
                        <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png" />
                    </div>
                </div>
            </header>
            <div className="app-new-auth-page login-bg-image">
                <div className="container h-100">
                    <div className="app-new-auth-page-wrapper">
                        <div className="app-new-auth-page-heading">
                            <h1>Welcome</h1>
                            <p>Please fill the form to Verify the tenant</p>
                        </div>
                        <div className='app-new-auth-page-card app-new-auth-page-card-tenant'>
                            {error ? <div className='d-flex justify-content-center align-items-center flex-column'>
                                <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                                <span className='font-weight-bold'>Your link got expired</span>
                            </div> : tenantAdded ? <div className='d-flex justify-content-center align-items-center flex-column'>
                                <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                                <span className='font-weight-bold'>Tenant added successfully</span>
                                <Button
                                    style={{ marginTop: 10 }}
                                    onClick={() => {
                                        window.location.href = `whatsapp://send?phone=+919505895078&text=${encodeURIComponent("Hi")}`;
                                    }}
                                    variant="contained"
                                >Done
                                </Button>
                            </div> : <form onSubmit={handleSubmit(currentStep == 1 ? createLandlord : currentStep == 2 ? createProperty : checkRewards)}>
                                <ul className="app-login-form-field-group">
                                    <li>
                                        <Grid container>
                                            <Grid item xs={12} className='grid-class'>
                                                <div className="app-view-stepper">
                                                    <ul className="app-view-stepper-list">
                                                        <li className="app-view-stepper-list-item">
                                                            <div className="app-view-stepper-list-item-info">
                                                                <h6>Step 1</h6>
                                                                <p>Landlord Details</p>
                                                            </div>
                                                        </li>
                                                        <li className="app-view-stepper-list-item">
                                                            <div className="app-view-stepper-list-item-info">
                                                                <h6>Step 2</h6>
                                                                <p>Flat Details</p>
                                                            </div>
                                                        </li>
                                                        <li className="app-view-stepper-list-item">
                                                            <div className="app-view-stepper-list-item-info">
                                                                <h6>Step 3</h6>
                                                                <p>Tenant Details</p>
                                                            </div>
                                                        </li>

                                                    </ul>
                                                </div>
                                                {currentStep == 1 && <>
                                                    <span className='font-weight-bold fs-16'>Landlord Details</span>
                                                    <div className="app-register-form-field mt-20">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="Landlord first name"
                                                                    className="app-login-form-field"
                                                                style={{border:'1px solid #DBE5ED',width:'100%',height:50,paddingLeft:10,}}

                                                                    {...register("first_name")}
                                                                />
                                                                {errors?.first_name && <span className="app-warning-content">{errors?.first_name?.message}</span>}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="Landlord last name"
                                                                    className="app-login-form-field"
                                                                style={{border:'1px solid #DBE5ED',width:'100%',height:50,paddingLeft:10,}}

                                                                    {...register("last_name")}
                                                                />
                                                                {errors?.last_name && <span className="app-warning-content">{errors?.last_name?.message}</span>}

                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="app-register-form-field mt-10" style={{ marginBottom: 20 }}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} lg={6}>
                                                            <PhoneInput
                                                            country={'in'}
                                                            value={getValues().mobile}
                                                            onChange={(value) => {setValue("mobile",value)}}
                                                            countryCodeEditable={false}
                                                            enableSearch={true}
                                                            disabled={true}
                                                            placeholder="Landlord Whatsapp number"
                                                            inputStyle={{ width: '100%' ,height:50}}
                                                        />
                                                                {/* <input
                                                                    type={"text"}
                                                                    placeholder="Landlord Mobile number"
                                                                    className="app-login-form-field"
                                                                    {...register("mobile")}
                                                                /> */}
                                                                {errors?.mobile && <span className="app-warning-content">{errors?.mobile?.message}</span>}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="Landlord email"
                                                                    className="app-login-form-field"
                                                                style={{border:'1px solid #DBE5ED',width:'100%',height:50,paddingLeft:10,}}

                                                                    {...register("email")}
                                                                />
                                                                {errors?.email && <span className="app-warning-content">{errors?.email?.message}</span>}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </>}
                                              


                                                {currentStep == 2 && <>
                                                    <span className='font-weight-bold fs-16' >Flat Details</span>
                                                    <div className="app-register-form-field mt-10">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="Flat number"
                                                                    className="app-login-form-field"
                                                                    disabled={true}
                                                                    {...register("flat_number")}
                                                                />
                                                                {errors?.flat_number && <span className="app-warning-content">{errors?.flat_number?.message}</span>}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="Block number"
                                                                    className="app-login-form-field"
                                                                    disabled={true}
                                                                    {...register("block_number")}
                                                                />
                                                                {errors?.block_number && <span className="app-warning-content">{errors?.block_number?.message}</span>}

                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="app-register-form-field mt-10">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"number"}
                                                                    placeholder="No of bedrooms"
                                                                    className="app-login-form-field"
                                                                    {...register("no_of_bedooms")}
                                                                />
                                                                {errors?.no_of_bedooms && <span className="app-warning-content">{errors?.no_of_bedooms?.message}</span>}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="No of bathrooms"
                                                                    className="app-login-form-field"
                                                                    {...register("no_of_bathooms")}
                                                                />
                                                                {errors?.no_of_bathooms && <span className="app-warning-content">{errors?.no_of_bathooms?.message}</span>}

                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="app-register-form-field mt-10">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"number"}
                                                                    placeholder="No of carparks"
                                                                    className="app-login-form-field"
                                                                    {...register("no_of_carparks")}
                                                                />
                                                                {errors?.no_of_carparks && <span className="app-warning-content">{errors?.no_of_carparks?.message}</span>}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"number"}
                                                                    placeholder="square feets"
                                                                    className="app-login-form-field"
                                                                    {...register("square_feet")}
                                                                />
                                                                {errors?.square_feet && <span className="app-warning-content">{errors?.square_feet?.message}</span>}

                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="app-register-form-field mt-10">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="Property face"
                                                                    className="app-login-form-field"
                                                                    {...register("property_face")}
                                                                />
                                                                {errors?.property_face && <span className="app-warning-content">{errors?.property_face?.message}</span>}
                                                            </Grid>

                                                        </Grid>
                                                    </div>
                                                </>}


                                                {currentStep == 3 && <>
                                                <div style={{ marginBottom: "20px" }}>
                                                    <span className='font-weight-bold fs-16'>Select Verification Services</span>
                                                    {
                                                        verificationServices?.map((item) => {
                                                            return <div className="app-register-form-field mt-3">
                                                            <Grid container spacing={2}>
                                                                <div style={{display:'flex',alignItems:'center'}}>
                                                                    <Checkbox checked={selectedServices.includes(item?._id)} onChange={(e) => {
                                                                        if(item?.isMandatory) return
                                                                        if(selectedServices.includes(item?._id)) {
                                                                            setSelectedServices(selectedServices.filter(service => service != item?._id))
                                                                            return
                                                                        }
                                                                        setSelectedServices(prev => [...prev,item?._id])
                                                                    }}/>
                                                                    <span style={{ fontSize: 12, marginLeft: 0, }}>{item?.name}</span>
                                                                </div>
                                                            </Grid>
                                                        </div>
                                                        })
                                                    }
                                                </div>
                                                    <span className='font-weight-bold fs-16'>Tenant Details</span>
                                                    <div className="app-register-form-field mt-10">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="Tenant first name"
                                                                    className="app-login-form-field"
                                                                    {...register("tenant_first_name")}
                                                                />
                                                                {errors?.tenant_first_name && <span className="app-warning-content">{errors?.tenant_first_name?.message}</span>}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="Tenant last name"
                                                                    className="app-login-form-field"
                                                                    {...register("tenant_last_name")}
                                                                />
                                                                {errors?.tenant_last_name && <span className="app-warning-content">{errors?.tenant_last_name?.message}</span>}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="app-register-form-field mt-10" style={{ marginBottom: 20 }}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="Tenant Mobile number"
                                                                    className="app-login-form-field"
                                                                    {...register("tenant_mobile")}
                                                                />
                                                                {errors?.tenant_mobile && <span className="app-warning-content">{errors?.tenant_mobile?.message}</span>}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="Tenant email"
                                                                    className="app-login-form-field"
                                                                    {...register("tenant_email")}
                                                                />
                                                                {errors?.tenant_email && <span className="app-warning-content">{errors?.tenant_email?.message}</span>}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="app-register-form-field mt-10" style={{ marginBottom: 20 }}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} lg={6}>
                                                                <Select onChange={(e) => { setValue('type',e?.value) }} options={[{ value: "Existing", label: "Existing" },{ value: "Prospective", label: "Prospective / New Tenant" }]} placeholder="Select tenant type"/>

                                                                {errors?.type && <span className="app-warning-content">{errors?.type?.message}</span>}
                                                            </Grid>
                                                          { hasCotenantService() && <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"number"}
                                                                    placeholder="Co tenant count"
                                                                    className="app-login-form-field"
                                                                    {...register("coTenantsCount")}
                                                                />
                                                            </Grid>}
                                                        </Grid>
                                                    </div>


                                                </>}
                                            </Grid>
                                        </Grid>
                                    </li>
                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="app-auth-button justify-content-center">
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        disabled={loading}
                                                    >
                                                        {loading ? <CircularProgress size={25} color='inherit' /> : 'Next'}
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </li>
                                </ul>
                            </form>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
